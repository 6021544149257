import { Box, Button } from "@chakra-ui/react"
import { GetServerSidePropsContext, InferGetServerSidePropsType } from "next"
import { UserConfig } from "next-i18next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import Head from "next/head"
import { useRouter } from "next/router"
import { namespaces } from "../../../domains.config"
import nextI18nextConfig from "../../../next-i18next.config"
import { brands, QR_CODE_REF_LINK } from "../../../src/common/constants/constants"
import { useBrandedTranslation } from "../../../src/common/hooks/useBrandedTranslation"
import Breadcrumbs from "../../../src/components/shared/Breadcrumbs/Breadcrumbs"
import ErrorView from "../../../src/components/shared/ErrorView"
import RichTextLabel from "../../../src/components/shared/RichTextLabel"
import { CustomPageProps } from "../../_app"

type Props = InferGetServerSidePropsType<typeof getServerSideProps> & CustomPageProps

const NotFoundPage = ({ brand, isQrCodeLink }: Props) => {
  const { t } = useBrandedTranslation()
  const router = useRouter()

  const crumbs = [{ name: t("page-error-breadcrumb"), href: "/" }]

  return (
    <>
      <Head>
        <title>{t("home-title")}</title>
        <meta name="robots" key="meta-robots" content="noindex, nofollow" />
      </Head>

      <Breadcrumbs crumbs={crumbs} />

      <ErrorView>
        {isQrCodeLink ? (
          <RichTextLabel
            i18nKey={"pageNotFound-print_rtf"}
            styleProps={{
              sx: {
                h1: {
                  fontSize: { base: "5xl", sm: "7xl", md: "8xl" },
                  fontWeight: brands[brand].mainTitleFontWeight,
                  lineHeight: 1.25,
                  mb: 0,
                  textTransform: "uppercase",
                  _odd: {
                    fontWeight: brands[brand].subTitleFontWeight,
                  },
                },
                h2: {
                  fontSize: { base: "lg", sm: "xl" },
                  fontWeight: "bold",
                  mt: 10,
                },
                fontSize: "md",
              },
            }}
          />
        ) : (
          <RichTextLabel
            i18nKey={"page-not-found_rtf"}
            styleProps={{
              sx: {
                h1: {
                  fontSize: { base: "5xl", sm: "7xl", md: "8xl" },
                  fontWeight: brands[brand].mainTitleFontWeight,
                  lineHeight: 1.25,
                  mb: 0,
                  textTransform: "uppercase",
                },
                h2: {
                  fontSize: { base: "5xl", sm: "7xl", md: "8xl" },
                  fontWeight: brands[brand].subTitleFontWeight,
                  lineHeight: 1.25,
                  mb: 0,
                  textTransform: "uppercase",
                },
                p: {
                  mt: 10,
                },
                fontSize: "md",
              },
            }}
          />
        )}

        <Box>
          <Button px="6" mt="10" border="1px solid" variant="secondary" color="black" onClick={() => router.push("/")}>
            {t("pageNotFound-goBackHome")}
          </Button>
        </Box>
      </ErrorView>
    </>
  )
}

export const getServerSideProps = async ({ locale, query, res }: GetServerSidePropsContext) => {
  const ref = query.ref?.toString()
  const isQrCodeLink = ref === QR_CODE_REF_LINK

  /* Return 404 error code instead of 200 success code for the custom 404 page */
  res.statusCode = 404

  return {
    props: {
      ...(await serverSideTranslations(locale, namespaces, nextI18nextConfig as UserConfig)),
      isQrCodeLink,
    },
  }
}

export default NotFoundPage
